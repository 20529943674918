
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import fetcher from '../../../api';
import { costForm } from '../../../utils';

const Products = ( { c, children } ) => {
  const [key, setKey] = useState(0);
  const { data: products } = useSWR('/api/products/' + (c?.id == 6 ? 'all_reverse?c1=' + c?.id : 'all?c1=' + c?.id || 5), fetcher);

  const products_s = products && products.rows && products.rows.reduce((obj, p) => {
    if(obj[p.SubCategoryId]){
      obj[p.SubCategoryId].rows = [
        p,
        ...obj[p.SubCategoryId].rows,
      ];
    } else {
      obj[p.SubCategoryId] = {
        ...p.SubCategory,
        rows: [ p ]
      }
    }
    return obj;
  }, {})
  const toggleAccordion = (idx, p) => () => {
    console.log(idx, p);
    setKey(key===idx?-1:idx);
    setSelected(p);
  };
  const [selected, setSelected] = useState(null);
  const [selectedSub, setSelectedSub] = useState(-1);
  useEffect(()=>{
    setKey(0);
    setSelected(null);
    setSelectedSub(-1);
  },[c])
  return (
    <section id={`scene0${c && c.hasThumbnail ? 5 : 2}`}>
      {children}
      <article className="inner">
          <div className="title">{c && c.name}</div>
          <div className="line"></div>
          {
            c && products && products.rows && products_s &&
              c.hasThumbnail && (
                <ul className={"tap_menu_list " + (c.SubCategories.length > 5 ? "tap_menu_list02" : "")}>
                  <li className={selectedSub === -1 ? 'active' : ''} onClick={() => setSelectedSub(-1)}>
                    <div className="">
                      전체
                      <span>{products.rows.length}</span>
                    </div>
                  </li>
                  {
                    c.SubCategories.map(({id, name}) => (
                      <li key={id} className={id === selectedSub ? 'active' : ''} onClick={() => setSelectedSub(id)}>
                        <div className="">
                          {name}
                          <span>{products.rows.filter(p => id === p.SubCategoryId).length}</span>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              )
          }
          {/*-------------------산업용 end*/}
          <ul className={`${c && c.hasThumbnail ? 'contents' : 'content'} clearfix`}>
              {
                c && products && products.rows && products_s &&
                  c.hasThumbnail ?
                    products.rows.filter(p => selectedSub === -1 || selectedSub === p.SubCategoryId)
                    .map(p => (
                      <li key={p.id}>
                        <Link to={`/product/${c.id}/${p.id}`}>
                          <>
                            <div className="img"><img src={p.thumbnail} alt=""/></div>
                            <div className="name"><strong>{p.name}</strong>{c.hasUsage ? p.metadata : costForm(p.cost)}</div>
                            <div className="txt">{p.feature}</div>
                          </>
                        </Link>
                      </li>
                    )) :
                    (
                    <>
                      <div className="tap_menu">
                      {
                        products_s && Object.values(products_s).map((s,i) => (
                          <div className={key===i ? 'on' : ''} key={s.id}>
                            <div className="accordion" onClick={toggleAccordion(i, s.rows[0])}>{s.name}<img src="/img/sub02/arrow.png" alt="arrow"/></div>
                            <ul className="panel">
                                  {
                                    s.rows.map((p, idx) => <li key={idx} onClick={()=>setSelected(p)} className={(p === selected || (selected === null && idx === 0 && i === 0)) ? 'active' : ''}>・ {p.name}</li>)
                                  }
                            </ul>
                          </div>
                        ))
                      }
                      </div>

                      {
                        products_s && Object.keys(products_s).length > 0 && (
                          <div className="body summernote-body" dangerouslySetInnerHTML={{ __html: selected?.body || products_s[Object.keys(products_s)[0]].rows[0].body}}/>
                        )
                      }
                      </>
                    )
              }
          </ul>
      </article>
    </section>
  )
}

export default Products;