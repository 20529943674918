import React, { useState } from 'react'
import Header from "../components/Header";
import Footer from "../components/Footer";
import Modal from '../components/Modal';
import { IoIosArrowDown } from 'react-icons/io'

const standards = [
    {
        title: '안전상의 조치',
        text: `- 작업장이 정리정돈되어 안전하고 청결한 상태를 유지하고 있으며,
  안전통로, 작업 발판, 비상구, 위험물질 보관장소 등의 설치상태가 적정할 것

- 위험기계기구에 방호장치 설치 등 방호조치가 되어있고,
    근원적 안전능 확보하기 위한 대체설비 등의 설치상태가 적정할 것

- 사용기계기구, 위험설비 및 용접설비 등에 방호조치가 되어있고,
    근원적 안전을 확보하기 위한 자동화설비 등의 설치상태가 적정할 것

- 하역운반기계에 안전벨트, 안전지주, 블록 등이 설치되어 있고,
    충돌방지를 위한 사각지대 반사경, 제한속도 표지 등의 설치상태가 적정할 것

- 전기설비에 충전부 방호 접지, 누전차단기 설치 등 감전방지도치가 되어있고,
    폭발위험잔소에 방폭, 양압설비, 비상전원장치 등의 설치 상태가 적정할 것

- 화학설비에 안전밸브, 파열판, 화염방지기 등이 설치되어 있고,
    증기, 가스, 분진 등을 제거하기 위한 소화, 통풍, 환기, 제진조치가 적정할 것

- 추락위험장소에 안전난간, 개구부 덮개설치 등이 설치되어 있고,
    사다리 등 고소작업을 위한 안전조치가 적정할 것`
    },
    {
        title: '작업환경 개선조치',
        text: `- 유해 물질 제조/사용/취급 장소에 가스, 증기, 분진 등을 밀폐, 배기하기 위한 조치가 되어있고,
  유해 물질 취급작업 대체설비의 설치 상태가 적정할 것

- 분진작업 장소에 분진을 감소하기 위한 밀폐, 배기 등의 조치가 되어있고,
    분진작업 대체설비의 설치 상태가 적정할 것

- 밀폐공간, 고온, 한랭, 다습작업 등의 장소에 환기설비, 세척, 세안 시설 등 설치 상태가 적정할 것

- 강렬한 소음이 발생하는 설비에 소음원을 밀폐, 흡음, 격리하기 위한 조치가 이루어지는 등 소음 감소 조치가 적정할 것

- 근로자가 작업하는 장소에 작업 종류에 따른 조도가 적정하게 확보되어 있고, 눈부심 등이 없을 것`
    },
    {
        title: '작업환경 개선조치',
        text: `- 근골격계 부담 작업 공정에 근골격계 부담요인을 제거/완화하기 위한 설비의 설치 상태가 적정할 것

- 근골격계 부담 작업 공정에는 인간공학적으로 설계된 인력 작업 보조 편의 설비의 설치 상태가 적정할 것`
    },
    {
        title: '기타 안전보건상의 조치',
        text: `- 유해 위험 작업 장소에서 작업하는 근로자에게 개인 보호구 및 보호장구를 지급하는 등
  근로자 개인보호 조치가 적정할 것

- 유해 위험한 시설 및 장소에는 근로자의 안전의식 고취를 위한 안전보건 표지 부착상태가 적정할 것

- 기타 유해 위험요인에 대한 제거, 격리, 밀폐, 차단, 대체 등의 조치가 적정할 것`
    },
    {
        title: '안전보건체제 이행여부 확인',
        text: `- 안전보건 교육 실시 여부
- 안전 인증 및 안전검사 실시 여부
- 유해 위험 방지 계획서 심사 확인 여부
- 공정안전보고서(psm) 심사 확인 여부
- 물질안전보건자료(MSDS) 비치·게시·교육 여부
- 작업환경측정 실시 여부
- 특수건강검진 실시 여부
- 무재해운동 추진 여부`
    }
]
const Clean = () => {
    const [modalVisible, setModalVisible] = useState(false)
    const [modalIndex, setModalIndex] = useState(-1);
    const openModal = () => {
        setModalVisible(true)
    }
    const closeModal = () => {
        setModalVisible(false)
    }
    return(
    <section className="scrollBox">
    <section className="wrap" id="sub04_page">
        <Header>
            <div className="title">
                <div>클린사업장 조성사업</div>
                <div>Comfort Life, Comfort Future, DEOCKWOO.</div>
            </div>
        </Header>
        <section id="scene02">
            <article className="sc02_inner">
                <div className="title">클린사업장 조성사업</div>
                <div className="line"></div>

                <div className="contents">
                    <p>사업 개요</p>
                    <p>
                        정부 지원에 힘입어<br/>
                        덕우물산과 함께 작업 환경을<br/>
                        더욱 안전하게 개선시켜보세요.
                    </p>
                    <p>
                        산업안전공단에서는 산업안전보건법에 의거하여
                        산업재해예방을 위한 기술.재정적 능력이 취약하여
                        재해발생 가능성이 높은 50인 미만 고위험 사업장의
                        안전보건관리 시스템 구축을 위한 보조지원을 통하여
                        “안전하고 건강한 일터”를 조성함으로써 산업재해
                        감소를 도모하고 있습니다.
                    </p>
                    <p>(산업안전보건법 제 62조[산업재해예방활동의 촉진])</p>
                </div>

                <div className="txt01">‘안전한 일터조성’을 방해하는</div>
                <div className="txt02">재해발생요인 3D</div>

                <ul className="txt_list">
                    <li>
                        <div className="num">01</div>
                        <div>Danger</div>
                        <div>
                            산업재해를<br/>
                            유발시키는 위험 요인
                        </div>
                    </li>
                    <li>
                        <div className="num">02</div>
                        <div>Dirtiness</div>
                        <div>
                            직업병을 유발시키는<br/>
                            유해한 작업환경요인
                        </div>
                    </li>
                    <li>
                        <div className="num">03</div>
                        <div>Difficulty</div>
                        <div>
                            불안전한 행동을 유발하는<br/>
                            힘들고 불편한 작업
                        </div>
                    </li>
                </ul>
            </article>


        </section>


        <section id="scene03">
            <article className="sc03_inner inner">
                <div className="sc03_title01">산업 재해를 예방하여</div>
                <div className="sc03_title02">안전하고 건강한 일터를 조성합니다.</div>

                <ul className="img_wrap clearfix">
                    <li><img src="img/sub04/sc03_img01.jpg" alt=""/></li>
                    <li>
                        <div className="gray_box">
                            <p>01. Danger</p>
                            <p>안전설비 개선</p>
                            <p>
                                전도재해 예방을 위한 바닥도장 공사, 적재대, 공구대 추락재해
                                예방을 위한 안전난간대, 전도방지사다리, 고소작업대 협착재해
                                예방을 위한 보호장치, 방호덮개, 자동화설비 전기시설 충전부
                                방호장치, 방폭시설등
                            </p>
                        </div>
                    </li>
                    <li><img src="img/sub04/sc03_img02.jpg" alt=""/></li>
                    <li>
                        <div className="gray_box">
                            <p>02. Dirtiness</p>
                            <p>작업환경 개선</p>
                            <p>
                                분진, 유해물질 제거를 위한 국소재기장치, 전체환기시설
                                소음방지시설, 조명시설, 개인보호장구 등
                            </p>
                        </div>
                    </li>
                    <li><img src="img/sub04/sc03_img03.jpg" alt=""/></li>
                    <li>
                        <div className="gray_box">
                            <p>03. Difficulty</p>
                            <p>작업공정 개선</p>
                            <p>
                                근골격계질환 예방을 위한 전동지게차, 이동식대차, 에어발란스
                                높낮이조절작업대, 인력운반보조기구 등
                            </p>
                        </div>
                    </li>
                </ul>

            </article>


        </section>





        <section id="scene04">
            <article className="inner">
                <div className="sc04_title">
                    지원 대상 및 조건에<br/>
                    충족되는지 살펴보세요.
                </div>
                <div className="txt01 clearfix">
                    <ul>
                        <li>지원 대상</li>
                        <li>・ 50인 미만의 제조업 사업장</li>
                        <li>・ 위험성평가 참여 사업장</li>
                        <li>・ 고용부/공단/민간위탁기관의 감독/점검/기술지도를 받은 사업장</li>
                    </ul>
                    <ul>
                        <li>지원 조건<a href="#" onClick={openModal}>인정 기준</a></li>
                        <li>・ 기술지원시 제기된 모든 문제점을 개선하여 클린사업장 인정평가 기준 충족</li>
                        <li>・ 사업주교육참석</li>
                        <li>・ 기술지원 시 제기된 재해발생 유해.위험요인 제거를 위한 일부개선</li>
                    </ul>
                </div>

                <div className="txt02">
                    <div>지원 절차</div>
                    <img className="txt02_img sc04_img01" src="img/sub04/sc04_img.png"/>
                    <img className="txt02_img sc04_img02" src="img/sub04/sc04_img01.png"/>
                </div>

                <div className="txt03">
                    <div>보조금 지원</div>
                    <img className="txt02_img" src="img/sub04/sc04_img2.png"/>
                </div>

            </article>
        </section>
        </section>
        <Footer />
        {
            modalVisible &&
            <Modal
                visible={modalVisible}
                closable={true}
                maskClosable={true}
                onClose={closeModal}>
                <div className="faq">
        <p className="t1">인정 기준</p>
        <ul>
            {
                standards.map((s,i) => (
                    <li className={modalIndex === i ? "act" : ''}>
                        <a href="#" className="tit" onClick={()=>setModalIndex(modalIndex === i ? -1 : i)}>{s.title}<IoIosArrowDown size="24" color="#000"/></a>
                        <div>{s.text}</div>
                    </li>
                ))
            }
        </ul>
    </div>
            </Modal>
        }
    </section>
    );
}

export default Clean;