import React from 'react'
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { df } from '../../utils';
import { useParams, useLocation, Link, useHistory } from 'react-router-dom';
import useSWR from 'swr';
import fetcher from '../../api';

const data = [];
const Clean = () => {
    const location = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(location.search);
    const page = query.get('page') || 1;
    const { year } = useParams();
    const { data } = useSWR(`/api/performances?page=${page}&year=${year}`, fetcher);

    const count = parseInt(((data?.count || 10)-1) / 10) + 1;
    const mp = parseInt((page-1) / 10) * 10 + 1;
    const Mp = count <= 10 ? count : Math.min(count, mp + 9);
    const pages = [];
    for(let i=mp;i<=Mp;i++) pages.push(i);

    return(
    <section className="scrollBox">
        <section className="wrap" id="sub05_page">
            <Header>
                <div className="title">
                    <div>환경장비 납품실적</div>
                    <div>Comfort Life, Comfort Future, DEOCKWOO.</div>
                </div>
            </Header>
            <section id="scene02">
                <article className="inner">
                    <div className="title">환경장비 납품실적</div>
                    <div className="line"></div>
                    <div className="sub_title">
                        국내 수 많은 기업들이<br/>
                        <strong>덕우물산과 함께했습니다.</strong>
                    </div>
                    <div className="tap_list">
                        <select value={year} onChange={e=>history.push('/perform/'+e.target.value)}>
                            <option value="2020">2020년</option>
                            <option value="2019">2019년</option>
                            <option value="2018">2018년</option>
                            <option value="2017">2017년</option>
                        </select>
                    </div>
                    <div className="board">
                    <div className="board_wrapper">
                        <div className="board_content">
                            <div className="board_header">
                                <div className="board_num">번호</div>
                                <div className="board_">납품처</div>
                                <div className="board_">제품명</div>
                                <div className="board_">규격</div>
                                <div className="board_quantity">수량</div>
                                <div className="board_date">납품일</div>
                            </div>
                            <ul className="board_list">
                                {
                                data && data.rows &&
                                data.rows.map(p => (
                                    <li key={p.id}>
                                        <div className="board_num">{p.id}</div>
                                        <div className="board_">{p.supply}</div>
                                        <div className="board_">{p.productName}</div>
                                        <div className="board_">{p.standard}</div>
                                        <div className="board_quantity">{p.quantity}</div>
                                        <div className="board_date">{df(p.deliveryDate)}</div>
                                    </li>
                                ))
                                }
                            </ul>
                        </div>
                    </div>
                    <ul className="num clearfix">
                        {pages.map(p => (
                        <li className={page==p ? 'on' : ''} key={p}>
                            <Link to={`?page=${p}&year=${year}`}>{p}</Link>
                        </li>
                        ))}
                    </ul>
                    </div>
                </article>
            </section>
        </section>
        <Footer />
    </section>
    );
}

export default Clean;