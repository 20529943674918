import React from 'react'
import { Switch, useParams, useRouteMatch, Route, Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CM from './CM';
import Domain from './Domain';
import Depart from './Depart';
import Afflite from './Afflite';
import Come from './Come';

const _nav = [
    {
        link: 'cm',
        name: 'CEO Message'
    },
    {
        link: 'domain',
        name: '사업 영역'
    },
    {
        link: 'depart',
        name: '사업 부서'
    },
    {
        link: 'afflite',
        name: '제휴 업체'
    },
    {
        link: 'come',
        name: '오시는 길'
    }
]
const IntroLayout = () => {
    const { type } = useParams();
    const { path } = useRouteMatch();
    const nav = (
        <ul className="sub_gnb">
            {
                _nav.map(c => (
                    <li key={c.link} className={type===c.link ? 'on' : ''}>
                        <Link to={`/intro/${c.link}`}>{c.name}</Link>
                    </li>
                ))
            }
        </ul>
    );
    return (
        <section className="scrollBox">
            <section className="wrap" id="sub01_page">
                <Header>
                    <div className="title">
                        <div>회사소개</div>
                        <div>Comfort Life, Comfort Future, DEOCKWOO.</div>
                    </div>
                </Header>
                <Switch>
                    <Route path="/intro/cm">
                        <CM>
                            {nav}
                        </CM>
                    </Route>
                    <Route path="/intro/domain">
                        <Domain>
                            {nav}
                        </Domain>
                    </Route>
                    <Route path="/intro/depart">
                        <Depart>
                            {nav}
                        </Depart>
                    </Route>
                    <Route path="/intro/afflite">
                        <Afflite>
                            {nav}
                        </Afflite>
                    </Route>
                    <Route path="/intro/come">
                        <Come>
                            {nav}
                        </Come>
                    </Route>
                </Switch>
            </section>
            <Footer />
        </section>
    )
}

export default IntroLayout;