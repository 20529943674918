import React from 'react'
import { Switch, useParams, useRouteMatch, Route, Link } from "react-router-dom";
import { community } from "../../../constants";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Communities from './index';
import Community from './[id]';

const CommunityLayout = () => {
    const { type } = useParams();
    const { path } = useRouteMatch();
    const nav = (
        <ul className="sub_gnb">
            {
            Object.entries(community).map(([k, {name}]) => (
                <li key={name} className={type==k ? 'on' : ''}>
                    <Link to={`/community/${k}`}>{name}</Link>
                </li>
            ))
            }
        </ul>
    );
    return (
        <section className="scrollBox">
            <section className="wrap" id="sub03_page">
                <Header>
                    <div className="title">
                        <div>커뮤니티</div>
                        <div>Comfort Life, Comfort Future, DEOCKWOO.</div>
                    </div>
                </Header>
                <Switch>
                    <Route path={path+'/:id'}>
                        <Community type={type}>
                            {nav}
                        </Community>
                    </Route>
                    <Route path={path}>
                        <Communities type={type}>
                            {nav}
                        </Communities>
                    </Route>
                </Switch>
            </section>
            <Footer />
        </section>
    )
}

export default CommunityLayout;