import React, { useState } from 'react';
import { community } from '../../../constants';
import { df } from '../../../utils';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import fetcher from '../../../api';

const Communities = ({type, children}) => {
  const query = new URLSearchParams(useLocation().search);
  const page = query.get('page') || 1;
  const search = query.get('search');
  const { data } = useSWR(`/api/posts/${type}?page=${page}${search ? '&search='+search:''}`, fetcher);

  const count = parseInt(((data?.count || 10)-1) / 10) + 1;
  const mp = parseInt((page-1) / 10) * 10 + 1;
  const Mp = count <= 10 ? count : Math.min(count, mp + 9);
  const pages = [];
  for(let i=mp;i<=Mp;i++) pages.push(i);

  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const keyPress = e => {
    if(e.key == 'Enter'){
      if(!searchText){
        alert('내용을 입력해주세요.');
        return;
      }
      history.push({
        pathname: '/community/' + type,
        search: '?search=' + searchText
      })
    }
  }

  return (
    <section id={!community[type].hasThumbnail ? "scene02" : "scene03"}>
      { children }
      <article className="inner">
          <div className="title">{ community[type].name }</div>
          <div className="line"></div>

          <div className={community[type].hasThumbnail ? 'content_box' : 'board'}>
            { !community[type].hasThumbnail ?
              (
              <div className="board_wrapper">
                <div className="board_content">
                  <div className="board_header">
                    <div className="board_num">번호</div>
                    <div className="board_title">제목</div>
                    <div className="board_date">작성일</div>
                  </div>
                  <ul className="board_list">
                      {
                        data && data.rows &&
                        data.rows.map(post => (
                          <Link key={post.id} to={"/community/" + type + "/" + post.id}>
                            <div className="board_num">{post.id}</div>
                            <div className="board_title">{post.title}</div>
                            <div className="board_date">{df(post.createdAt)}</div>
                          </Link>
                        ))
                      }
                  </ul>
                </div>
              </div>
              ) :
              (
                <ul className="content_list clearfix">
                      {
                        data && data.rows &&
                        data.rows.map(post => (
                          <li>
                            <a href={
                              type == 'video' ?
                              "https://www.youtube.com/watch?v="+post.body :
                              "/community/" + type + "/" + post.id
                              }
                              >
                              <div className="img">
                              {
                                type == 'video' ?
                                <iframe frameborder="0" src={'https://www.youtube.com/embed/'+post.body}></iframe> :
                                <img src={post.thumbnail} />
                              }
                              </div>
                              <div className="name">{post.title}</div>
                              <div className="txt">{df(post.createdAt)}</div>
                            </a>
                          </li>
                        ))
                      }
                </ul>
              )
            }


              <ul className="num clearfix">
                {pages.map(p => (
                  <li className={page==p ? 'on' : ''} key={p}>
                    <Link to={`?page=${p}${search ? '&search='+search : ''}`}>{p}</Link>
                  </li>
                ))}
              </ul>


              <div className="search">
                <input
                  value={searchText}
                  onChange={e=>setSearchText(e.target.value)}
                  onKeyPress={keyPress}
                  type="text"
                  placeholder="검색어를 입력해주세요."/>
                </div>
          </div>
      </article>
    </section>
  )

}

export default Communities;