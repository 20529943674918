import React, { useState } from 'react'
import Header from "../components/Header";
import Footer from "../components/Footer";

const Clean = () => {



    const [company, setCompany] = useState('');
    const [region, setRegion] = useState('');
    const [manager, setManager] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [model, setModel] = useState('');
    const [use, setUse] = useState('');
    const [content, setContent] = useState('');
    const [file, setFile] = useState(null);
    const [agree, setAgree] = useState(false);
    const sendMail = async () => {
        if(!agree) {
            alert('개인정보 취급방침에 동의해주세요');
            return;
        }
        if(!company || !region || !manager || !phone || !model || !use || !content ){
            alert('필수내용을 입력해주세요.')
            return;
        }
        const data = new FormData();
        data.append('company', company);
        data.append('region', region);
        data.append('manager', manager);
        data.append('phone', phone);
        data.append('model', model);
        data.append('use', use);
        data.append('content', content);
        data.append('file', file);
        console.log(data);
        try {
            const res = await fetch('/api/consult/mail', {
                method: 'POST',
                headers: {
                },
                body: data
            })
        } catch(e) {
            console.log('send mail error', e);
        }
    }
    return(
    <section className="scrollBox">
    <section className="wrap" id="sub06_page">
        <Header>
            <div className="title">
                <div>견적 상담</div>
                <div>Comfort Life, Comfort Future, DEOCKWOO.</div>
            </div>
        </Header>
        <section id="scene02">
            <article className="inner">
                <div className="title">견적상담</div>
                <div className="line"></div>

                <ul className="board">
                    <li><span>기업명 <strong>*</strong></span><input type="text" placeholder="기업명을 입력해주세요." value={company} onChange={e=>setCompany(e.target.value)}/></li>
                    <li><span>지역 <strong>*</strong></span><input type="text" placeholder="지역을 선택해주세요." value={region} onChange={e=>setRegion(e.target.value)}/></li>
                    <li><span>담당자명 <strong>*</strong></span><input type="text" placeholder="담당자명을 입력해주세요." value={manager} onChange={e=>setManager(e.target.value)}/></li>
                    <li><span>연락처 <strong>*</strong></span><input type="text" placeholder="연락처를 입력해주세요." value={phone} onChange={e=>setPhone(e.target.value)}/></li>
                    <li><span>이메일</span><input type="email" placeholder="이메일주소를 입력해주세요." value={email} onChange={e=>setEmail(e.target.value)}/></li>
                    <li><span>제품 기종 <strong>*</strong></span><input type="text" placeholder="제품 기종을 선택해주세요." value={model} onChange={e=>setModel(e.target.value)}/></li>
                    <li><span>사용 용도 <strong>*</strong></span><input type="text" placeholder="제품 사용 용도를 입력해주세요." value={use} onChange={e=>setUse(e.target.value)}/></li>
                    <li><span>문의 내용 <strong>*</strong></span><textarea placeholder="문의 내용을 입력해주세요." value={content} onChange={e=>setContent(e.target.value)}></textarea></li>
                    <li><span>파일 첨부</span><input type="file" onChange={e=>setFile(e.target.files[0])} /></li>
                    <li><input type="checkbox" placeholder="기업명을 입력해주세요." value={agree} onChange={e=>setAgree(e.target.value)}/>개인정보 취급방침 내용에 동의합니다.<button>내용 보기</button></li>
                </ul>

                <button className="btn" onClick={sendMail}>상담 신청 완료</button>
            </article>


        </section>
        </section>
            <Footer />
    </section>
    );
}

export default Clean;