import React from 'react'

const Depart = ({children}) => {
    return (
        <section id="scene04">
            {children}
            <article className="inner">
                <div className="title">사업 부서</div>
                <div className="line"></div>
                <div className="txt_box">
                    <div className="line_box">
                        <ul className="txt clearfix">
                            <li>집진기 사업부</li>
                            <li>
                                산업용 벨트,<br/>
                                컨베어 사업부
                            </li>
                            <li>청소기 사업부</li>
                        </ul>

                        <div>
                            각 사업 분야별 세분화된 시스템으로 <br/>
                            보다 전문적이고 체계적인 서비스를 제공합니다.
                        </div>
                    </div>

                </div>
            </article>

        </section>
    )
}

export default Depart;