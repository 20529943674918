import React from 'react';
import Home from './pages';
import { Switch, Route, Redirect } from 'react-router-dom';
import ProductLayout from './pages/product/[category]/layout';
import CommunityLayout from './pages/community/[type]/layout';
import IntroLayout from './pages/intro/layout';
import Clean from './pages/Clean';
import Consult from './pages/Consult';
import Perform from './pages/perform/[year]';

function App() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/clean">
        <Clean />
      </Route>
      <Route path="/consult">
        <Consult />
      </Route>
      <Route path="/product">
        <Switch>
          <Route path="/product/:category">
            <ProductLayout />
          </Route>
          <Redirect to="/product/5" />
        </Switch>
      </Route>
      <Route path="/community">
        <Switch>
          <Route path="/community/:type">
            <CommunityLayout />
          </Route>
          <Redirect to="/community/notice" />
        </Switch>
      </Route>
      <Route path="/intro">
        <Switch>
          <Route path="/intro/:type">
            <IntroLayout />
          </Route>
          <Redirect to="/intro/cm" />
        </Switch>
      </Route>
      <Route path="/perform">
        <Switch>
          <Route path="/perform/:year">
            <Perform />
          </Route>
          <Redirect to={'/perform/'+new Date().getFullYear()} />
        </Switch>
      </Route>
    </Switch>
  );
}

export default App;
