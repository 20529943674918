import React from 'react'
import { Link } from 'react-router-dom'

export default props => {
  
  return (
    <li
      className={`${
          props.on ? "on" : ""
      }`}>
      <Link to={props.path}>{props.label}</Link>
    </li>
  );
};