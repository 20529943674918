import React from 'react'
import './Footer.css';
import { Link } from 'react-router-dom';

export default () => {
    return (
        <footer id="footer">
            <article className="inner">
                <ul className="bot_top">
                    <li><Link to="/">개인정보취급방침</Link></li>
                    <li><Link to="/intro">회사소개</Link></li>
                    <li><Link to="/intro/come">오시는길</Link></li>
                    <li><Link to="/consult">제품상담</Link></li>
                    <li><Link to="/">Copyright © www.deockwoo.com All rights reserved.</Link></li>
                </ul>

                <ul className="bot_bot">
                    <li>
                        <div><strong>TEL.</strong> 043)213-0002 / 043)212-2100</div>
                        <div><strong>FAX.</strong> 043)216-8300</div>
                        <div><strong>LOCATION.</strong>  충북 청주시 청원구 2순환로 364, 충북산업용재유통상가 104동 119호</div>
                    </li>
                    <li><Link to="/"><img src="/img/common/footer/footer_logo.png" alt="DEOCKWOO logo"/></Link></li>
                </ul>



            </article>

        </footer>
    );
}