import React, { useEffect, useState } from 'react'
import NavButton from './NavButton'
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

const navButtons = [
    {
        label: '회사소개',
        path: '/intro',
    },
    {
        label: '제품',
        path: '/product',
    },
    {
        label: '커뮤니티',
        path: '/community',
    },
    {
        label: '클린사업장 조성사업',
        path: '/clean',
    },
    {
        label: '환경장비 납품실적',
        path: '/perform',
    },
]
export default ({children}) => {
    const [on ,setOn] = useState(false);
    const location = useLocation();
    const pathname = location.pathname;
    const [className, setClassName] = useState('colored');

    const handleScroll = () => setClassName(window.pageYOffset > 0 ? 'colored' : '');

    useEffect(()=>{
        window.addEventListener('scroll', handleScroll);
    },[]);
    return (
        <section id="scene01">
            <header id="header" className={className}>
                <h1 className="logo"><Link to="/"><img src="/img/common/header/logo.png" alt="DEOCKWOO logo" /></Link></h1>

                <div className={"nav" + (on ? " on" : "")}>
                <button className={"hamburger hamburger--collapse"+(on ? " is-active" : "")} type="button" onClick={()=>setOn(!on)}>
                    <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                    </span>
                </button>
                    <ul className="gnb clearfix">
                        {
                            navButtons.map(button => (
                                <NavButton
                                    key={button.path}
                                    {...button}
                                    on={pathname.includes(button.path)}
                                />
                            ))
                        }
                    </ul>
                    <ul className="top_menu">
                        <li><Link to="/consult"><img src="/img/common/header/top_menu01.png" alt="견적 상담" /></Link></li>
                        <li><a href="/uploads/deockwoo-catalogue.zip" download><img src="/img/common/header/top_menu02.png" alt="제품 카다로그" /></a></li>
                        <li><a href="https://only.webhard.co.kr/login" target="_blank"><img src="/img/common/header/top_menu03.png" alt="웹하드" /></a></li>
                    </ul>
                </div>
            </header>
            {children}
        </section>
    )
}