import React from 'react'
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import { Switch, useRouteMatch, Link, Route, useParams } from 'react-router-dom';
import useSWR from 'swr';
import fetcher from '../../../api';
import Products from '.';
import Product from './[id]';

const ProductLayout = () => {
    let { path } = useRouteMatch();
    const { category } = useParams();
    const { data: categories } = useSWR('/api/products/category', fetcher);
    const c = categories && categories.find(c => c.id == category);
    const nav = (
        <ul className="sub_gnb sc05_sub_gnb">
            {
                categories &&
                categories.map(c => (
                    <li key={c.name} className={category==c.id ? 'on' : ''}>
                        <Link to={`/product/${c.id}`}>{c.name}</Link>
                    </li>
                ))
            }
        </ul>
    )
    return (
        <section className="scrollBox">
            <section className="wrap" id="sub02_page">
                <Header>
                    <div className="title">
                        <div>제품</div>
                        <div>Comfort Life, Comfort Future, DEOCKWOO.</div>
                    </div>
                </Header>
                <Switch>
                    <Route exact path={path}>
                        <Products c={c}>
                            {nav}
                        </Products>
                    </Route>
                    <Route path={`${path}/:id`}>
                        <Product>
                            {nav}
                        </Product>
                    </Route>
                </Switch>
            </section>
            <Footer />
        </section>
    )
}
export default ProductLayout;