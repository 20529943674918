import React from 'react'

const CM = ({children}) => {
    return (
        <section id="scene02">
            {children}
            <article className="inner">
                <div className="title">CEO Message</div>
                <div className="line"></div>
                <div className="txt_box">
                    <div>
                        Comfort Life,<br/>
                        Comfort Future.
                    </div>
                    <div />
                    <div>안녕하십니까?</div>
                    <div>
                        저희 덕우물산은 여러분들의 성원에 힘입어 1990년 창업이래 산업용 종합기자재를 공급하는 회사로 오늘에 이르게 되었습니다.
                        산업용 BELT, 고무제품, 콘베어, 산업용 힐타,환경기계, 합성수지등 이 업계에서 이렇게 우뚝 서기까지는 모든 것이
                        여러분의 사랑과 성원 덕분임을 깊이 인식하고 이 기회를 빌어 감사의 인사를 올리는 바입니다.
                    </div>
                    <div>
                        숨가쁘게 그러나 성실히 노력해 온 지난 10여년 동안 저희 목표는 오직 하나 품질만족, 고객 만족이었습니다.
                        앞으로 많은 조언과 질책을 부탁드리고 앞으로도 좋은 제품으로 성심 성의껏 모실 것을 약속드리며
                        아울러 귀사의 무궁한 발전과 모든 일에 행운이 함께하시길 기원합니다.
                    </div>
                    <div />
                    

                    <div>
                        <p>덕우물산(주) 대표&nbsp;&nbsp;&nbsp;</p>
                        <p><strong>오&nbsp;&nbsp;창&nbsp;&nbsp;덕</strong></p>
                    </div>

                </div>
            </article>
        </section>
    )
}

export default CM;