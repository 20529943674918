import React, { useRef, useState } from 'react'
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { community, baseUrl } from '../constants';
import { df } from '../utils';
import useSwr from 'swr';
import fetcher from '../api';
import Header from '../components/Header';

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="btn prev" onClick={onClick}>
            <img src="/img/main/sc02/prev.jpg" alt="previous" />
        </div>
                
    );
}

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="btn next" onClick={onClick}>
            <img src="/img/main/sc02/next.jpg" alt="next" />
        </div>
                
    );
}
const slides = [
    {
        labelEn: 'DUST COLLECTOR',
        labelKo: '집진기',
        img: 'img/main/sc02/img02.jpg',
        id: 5
    },
    {
        labelEn: 'INDUSTRIAL VACCUM',
        labelKo: '산업용 청소기',
        img: 'img/main/sc02/img04.jpg',
        id: 6
    },
    {
        labelEn: 'INDUSTRIAL BELT',
        labelKo: '산업용 벨트',
        img: 'img/main/sc02/img03.jpg',
        id: 7
    },
    {
        labelEn: 'CONVEYOR',
        labelKo: '콘베어',
        img: 'img/main/sc02/img01.jpg',
        id: 8
    },
]
const Home = () => {
  const { data:posts, error } = useSwr('/api/posts/entry', fetcher);
  const slider = useRef(null);
  const sliderConfig = {
    responsive: [
        {
          breakpoint: 1360,
          settings: {
            centerPadding: '10%',
          }
        },
        {
          breakpoint: 800,
          settings: {
            centerMode: false,
            centerPadding: '0',
          }
        }
    ],
    dots: false,
    arrows: true,
    centerMode: true,
    autoplay:false,
    autoplaySpeed: 2000,
    fade: false,
    speed: 900,
    infinite: true,
    slidesToShow: 1,
    centerPadding: '27.5%',
    swipe: true,
    draggable:true,
    vertical: false,
    verticalSwiping: false,
    initialSlide: 0,
    className: 'sc02_slides',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
  const [type, setType] = useState('notice');
  return (
    <section className="scrollBox">
        <section className="wrap" id="index_page">
        <Header>
            <ul className="title">
                <li>Comfort Life, Comfort Future.</li>
                <li>DEOCKWOO</li>
                <li><Link to="/intro">View more</Link></li>
            </ul>
        </Header>
        <section id="scene02">
            <ul className="title">
                <li>고객 만족을 위해 설계된</li>
                <li>독자적 기술력과 제품</li>
                <li>효율적인 업무 프로세스를 위한 우리의 노력,</li>
                <li>당신의 삶에 또 다른 가치를 만듭니다.</li>
            </ul>
            
            <Slider {...sliderConfig} ref={slider}>
              {
                  slides.map(slide => (
                      <div key={slide.labelEn}>
                        <img src={slide.img} alt="" />
                        <div className="overlay"></div>
                        <div className="txt">
                            <div>{slide.labelEn}</div>
                            <div>{slide.labelKo}</div>
                            <div><Link to={'/product/'+slide.id}><img src="img/main/sc02/plus.png" alt="+"/></Link></div>
                        </div>
                      </div>
                  ))
              }
            </Slider>

            <p>다양한 산업용 환경장비들을 만나보세요.</p>

            <ul className="sc02_nav">
                <li><Link to="/product/5" /></li>
                <li><Link to="/product/6" /></li>
                <li><Link to="/product/7" /></li>
                <li><Link to="/product/8" /></li>
            </ul>
        </section>
        <section id="scene03">
            <article className="left">
                <div className="sc03_img"><img src="img/main/sc03/sc03_bg01.png" alt="images01"/></div>
                <div className="txt">
                    <div>클린사업장 조성사업</div>
                    <div>3D 요인을 제거하고 안전한 일터를 조성하여</div>
                    <div>귀사의 쾌적한 발전을 도와드립니다.</div>
                    <div><a href="/clean">View more</a></div>
                </div>
            </article>
            <article className="right">
                <div className="sc03_img"><img src="img/main/sc03/sc03_bg02.png" alt="images02"/></div>
                <div className="txt">
                    <div>환경장비 납품실적</div>
                    <div>덕우물산의 뛰어난 제품과 기술력으로</div>
                    <div>국내 수 많은 기업들과 함께 성장합니다.</div>
                    <div><a href="/perform">View more</a></div>
                </div>
            </article>
        </section>
        <div id="scene04">
            <article className="inner">
                <div className="top">
                    <div className="tap_menu">
                        <div>
                            새로운 소식과<br/>
                            유용한 자료들을<br/>
                            만나보세요.
                        </div>
                        <ul className="tap">
                            {
                                Object.entries(community).map(([k, {name}]) => (
                                    <li key={name} className={type==k ? 'on' : ''}>
                                        <a onClick={()=>setType(k)}>{name}</a>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="txt_box clearfix">
                        {
                            posts &&
                            posts[type] &&
                            posts[type].map(post => (
                                <a href={
                                    type === 'video' ?
                                    'https://www.youtube.com/watch?v='+post.body :
                                    '/community/'+post.type+'/'+post.id}
                                    key={post.id}>
                                    <div className="tap_name">{community[type].name}</div>
                                    <div className="title">
                                        {post.title}
                                    </div>
                                    <div className="date">{df(post.createdAt)}</div>
                                </a>
                            ))
                        }
                    </div>
                </div>
                <div className="bot clearfix">
                    <div>빠른 전화 상담</div>
                    <div>043)213-0002</div>
                    <div><img src="img/common/footer/line.png" alt="/"/></div>
                    <div>043)212-2100</div>
                    <div><Link to="/consult">온라인 견적 상담 바로가기</Link></div>
                </div>
            </article>
        </div>
        <Footer />
        </section>
    </section>
  )
};

export default Home;