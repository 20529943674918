/* global kakao */
import React, { useEffect, useRef } from 'react'


const Come = ({children}) => {
    const map = useRef(null);
    useEffect(()=>{
        const pos = new kakao.maps.LatLng(36.673314, 127.479449);
        const map = new kakao.maps.Map(document.getElementById('map'),{
          center : pos,
          level: 3
        })
        const marker = new kakao.maps.Marker({
          position: pos
        })
        marker.setMap(map);
        const info = new kakao.maps.CustomOverlay({
          map: map,
          position : pos, 
          content : '<div class="marker"><a style=""href="https://map.kakao.com/link/map/덕우물산,36.673314,127.479449" style="color:blue" target="_blank"><span class="title">충북 청주시 청원구 2순환로 364</span></a></div>' 
        });
    })
    return (
        <section id="scene06">
            {children}
            <article className="inner">


                <div className="title">오시는 길</div>
                <div className="line"></div>

                <div id="map">
                </div>

                <ul className="txt">
                    <li><strong>ADDRESS</strong><div>충북 청주시 청원구 2순환로 364, <span>충북산업용재유통상가 104동 119호</span></div></li>
                    <li><strong>TEL</strong><div>043)213-0002&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;043)212-2100</div></li>
                    <li><strong>FAX</strong><div>043)216-8300</div></li>
                </ul>
            </article>

        </section>
    )
}

export default Come;