import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import fetcher from '../../../api';
import useSWR from 'swr';
import { costForm } from '../../../utils';

const Product = ({children}) => {
  const {category, id} = useParams();
  const { data: product } = useSWR('/api/products/' + id, fetcher);
  
  return (
    <section id="scene06">
      { children }
      {
        product &&
        (
        <>
          <div className="contents">
            <div className="inner clearfix">
                <div><img src={product.thumbnail} alt={product.name} /></div>
                <div className="txt_box">
                    <div className="top">
                        <p>{product.name}</p>
                        <p>{product.metadata}</p>
                    </div>
                    <div className="bot">
                    {
                      category === '5' ? (
                        <>
                          <div className="meta">
                            <div className="metaname">제품 특징</div>
                            <div className="metadata">{product.feature}</div>
                          </div>
                          <div className="meta">
                            <div className="metaname">필터 분류</div>
                            <div className="metadata">{product.filter}</div>
                          </div>
                          <div className="meta">
                            <div className="metaname">사용처</div>
                            <div className="metadata">{product.usage}</div>
                          </div>
                        </>
                      ) : (
                          <>
                          
                            <div className="meta">
                              <div className="metaname">원산지</div>
                              <div className="metadata">{product.origin}</div>
                            </div>
                            <div className="meta">
                              <div className="metaname">제조사</div>
                              <div className="metadata">{product.manufacturer}</div>
                            </div>
                            <div className="meta">
                              <div className="metaname">제품 특징</div>
                              <div className="metadata">{product.feature}</div>
                            </div>
                            <div className="meta">
                              <div className="metaname">상품가</div>
                              <div className="metadata">{costForm(product.cost)}</div>
                            </div>
                        </>
                      )
                    }
                    </div>
                    <Link to="/consult">
                      <div className="btn">견적 상담하기</div>
                    </Link>
                </div>
            </div>
          </div>
          <ul className="content_box" dangerouslySetInnerHTML={{__html: product.body}}>
          </ul>
        </>
        )
      }
    </section>
  )
}

export default Product;