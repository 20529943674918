export const df = dateText => {
    const d = new Date(dateText);
    const year = d.getFullYear();
    const month = pad('' + (d.getMonth() + 1), 2);
    const day = pad('' + d.getDate(), 2);
    const hour = pad(d.getHours(), 2);
    const minute = pad(d.getMinutes(), 2);
    const second = pad(d.getSeconds(), 2);
    return [year, month, day].join('.');
}

const pad = (n, width, z = '0') => {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export const costForm = cost => {
    if (isNaN(parseInt(cost))) return '';
    return Intl.NumberFormat('ko-KO', { style: 'currency', currency: 'KRW' }).format(cost)
}