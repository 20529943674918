import React from 'react'

const Afflite = ({children}) => {
    return (
        <section id="scene05">
            {children}
            <article className="inner">
                <div className="title">제휴 업체</div>
                <div className="line"></div>
                <div className="box_wrapper">
                    <ul className="company_box">
                        <li><a href="https://www.forbo.com/movement/de-de/" target="_blank"><img src="/img/sub01/sc05_img01.jpg" alt="SIEGLING"/></a></li>
                        <li><a href="http://www.nitta.co.jp/" target="_blank"><img src="/img/sub01/sc05_img02.jpg" alt="NITTA"/></a></li>
                        <li><a href="https://www.continental.com/ko-kr" target="_blank"><img src="/img/sub01/sc05_img03.png" alt="CONTINENTAL"/></a></li>
                        <li><a href="https://www.mitsuboshi.com/english/index.html" target="_blank"><img src="/img/sub01/sc05_img04.jpg" alt="MITSUBOSHI"/></a></li>
                        <li><a href="http://www.woojinbrick.com/" target="_blank"><img src="/img/sub01/sc05_img05.jpg" alt="우진연와하우"/></a></li>
                        <li><a href="http://www.deockwoo.com/" target="_blank"><img src="/img/sub01/sc05_img06.png" alt="덕우물산"/></a></li>
                        <li><a href="http://www.dwoo119.co.kr" target="_blank"><img src="/img/sub01/sc05_img07.png" alt="덕우물산"/></a></li>
                    </ul>
                </div>
            </article>

        </section>
    )
}

export default Afflite;