import React from 'react'
import { useParams, Link } from 'react-router-dom'
import useSWR from 'swr';
import fetcher from '../../../api';
import { community } from '../../../constants';
import { df } from '../../../utils';

const re = /\/uploads\/(.*)-[0-9]*\.(\w+)/;

const Community = ({children, type}) => {
    const { id } = useParams();
    const { data: post } = useSWR('/api/posts/' + type + '/' + id, fetcher );
    let fileName = '';
    if(post && post.file){
        fileName = post.file.replace(re, '$1.$2')
    }
    return (
        <scetion id="scene03">
            {children}
            {
                post &&
                (
                    <article className="inner">
                        <div className="title">{ community[type].name }</div>
                        <div className="line"></div>
                        <article className="txt_box">
                            <div className="title">
                                <div>{post.title}</div>
                                <div class="date">{df(post.createdAt)}</div>
                            </div>
                            <div className="txt summernote-body" dangerouslySetInnerHTML={{__html: post.body}}>
                            </div>
                            {
                                community[type].hasFile &&
                                (
                                    <div className="file">
                                        <div className="file_title">첨부파일</div>
                                        <div className="download">
                                            <a download={fileName} href={post.file}>
                                                {fileName}
                                            </a>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="list">
                                <Link to={"/community/" + type}>목록으로</Link>
                            </div>
                        </article>
                    </article>
                )
            }
        </scetion>
    )
}

export default Community;