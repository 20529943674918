import React from 'react'
import { Link } from 'react-router-dom';

const CM = ({children}) => {
    return (
        <section id="scene03">
            {children}
            <div className="title">사업 영역</div>
            <article className="sc03_inner">
                <div className="line"></div>
                <div>01. PRODUCT</div>
                <div>
                    독자적 혁신 기술을 반영한 <br/>
                    산업용 종합기자재 제공
                </div>
                <div className="txt_box">
                    <div>
                        <div className="inner">
                            <div className="box_title">
                                집진기
                                <Link to="/product/5"><img src="/img/sub01/plus.png" alt="+"/></Link>
                            </div>
                            <ul className="box_list">
                                <li>카트리지형 집진기 (AP Series)</li>
                                <li>백필터형 집진기 (MS Series)</li>
                                <li>청소기 부착형 집진기 (APV/MSV Series)</li>
                                <li>가로높이형 집진기 (APH Series)</li>
                                <li>바스켓형 집진기 (API/MSI Series)</li>
                                <li>안전대책형 집진기 (APD/MSD Series) 등</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="inner">
                            <div className="box_title">
                                청소기
                                <Link to="/product/6"><img src="/img/sub01/plus.png" alt="+"/></Link>
                            </div>
                            <ul className="box_list">
                                <li>업소용 청소기</li>
                                <li>산업용 청소기</li>
                                <li>링브로워 청소기</li>
                                <li>기능성 청소기</li>
                                <li>마루 광택기</li>
                                <li>보조탱크 운반구</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="inner">
                            <div className="box_title">
                                산업용 벨트
                                <Link to="/product/7"><img src="/img/sub01/plus.png" alt="+" /></Link>
                            </div>
                            <ul className="box_list">
                                <li>Timing Belt(MISUBOSHI)</li>
                                <li>Poly Belt(NITTA, SIEGLING)</li>
                                <li>Timing Pulley(TTW)</li>
                                <li>V-BELT</li>
                                <li>산업용 특수 BELT</li>
                                <li>V/S BELT 등</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="inner">
                            <div className="box_title">
                                콘베어
                                <Link to="/product/8"><img src="/img/sub01/plus.png" alt="+"/></Link>
                            </div>
                            <ul className="box_list">
                                <li>벨트콘베어</li>
                                <li>로울러콘베어</li>
                                <li>각종 리프트</li>
                                <li>운반기계</li>
                                <li>상/하차용 콘베어</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div>02. SERVICE</div>
                <div>
                    안전하고 건강한 일터를 위한 <br/>
                    작업 환경 개선 지원
                </div>

                <div className="txt_box02">
                    <div>클린사업장 조성 사업</div>
                    <div>
                        산업안전공단에서는 산업안전보건법에 의거하여 산업재해예방을 위한<br/>
                        기술.재정적 능력이 취약하여 재해발생 가능성이 높은 50인 미만<br/>
                        고위험 사업장의 안전보건관리 시스템 구축을 위한 보조지원을 통하여<br/>
                        “안전하고 건강한 일터”를 조성함으로써 산업재해를 감소를 도모하고<br/>
                        있습니다. (산업안전보건법 제 62조[산업재해예방활동의 촉진])<br/>
                    </div>
                    <div><Link to="/clean">자세히 알아보기</Link></div>
                    <div className="hat"><img src="/img/sub01/sc03_img01.png" alt="+"/></div>
                </div>
            </article>
        </section>
    )
}

export default CM;